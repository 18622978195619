<template>
  <div class='flex flex-col lg:flex-row border-b lg:border'>
    <div class='w-full lg:w-1/4 flex-grow-0 flex-shrink-0 lg:rounded-l-lg lg:border-r overflow-y-hidden bg-gray-100'
      style='height: 13rem;'>
      <img :src='poster.thumbnailUrl' class='w-full p-4 object-cover' />
    </div>
    <div class='px-2 lg:px-4 pt-2 lg:pt-4 pb-4 lg:pb-4 lg:flex-grow'>
      <h4 class='uppercase text-xs text-gray-800 leading-5 lg:leading-6 text-right float-right'>{{poster.posterNo}}</h4>
      <h2 class='w-4/5 font-base leading-5 lg:leading-6 text-base text-gray-700 mb-2'>{{poster.title}}</h2>
      <h3 class='w-4/5 font-medium leading-5 lg:leading-6 text-base text-gray-900'>{{authorsString}}</h3>
      <v-clamp
        v-if='hasAffiliation'
        class='w-4/5 font-base leading-5 text-sm text-gray-900 mb-2 whitespace-pre-line'
        :max-lines='2'>
         {{poster.authors.affiliation}}
      </v-clamp>
      <h3 class='font-light text-gray-700'>{{tagsString}}</h3>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
export default {
  name: 'PosterRow',
  components: {
    VClamp
  },
  props: ['poster'],
  computed: {
    tagsString () {
      if (this.poster && this.poster.conferenceTags) {
        let tagList =  this.poster.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
    authorsString () {
      if (this.poster && this.poster.authors) {
        return this.poster.authors.list.join(', ')
      } else {
        return ''
      }
    },
    hasAffiliation () {
      return this.poster.authors.affiliation
    },
  },
}
</script>
