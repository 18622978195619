<template>
  <div class='pt-0 lg:pt-12'>
    <sponsors-carousel-from-config :carousel-sponsors='eventConfigPostersCarouselSponsors' />
    <div v-if='eventFileListOnPosters.length > 0' class='flex flex-row justify-end gap-x-1 mb-4 lg:mb-12 text-sm'>
      <file-downloader
        v-for='(file, index) in eventFileListOnPosters'
        :key='`file-${index}`'
        :file='file'/>
    </div>
    <div class='flex flex-col lg:flex-row justify-between gap-x-6 gap-y-4'>
      <poster-controls class='w-full lg:w-48 flex-grow-0 flex-shrink-0 px-2 lg:px-0' />
      <div class='flex-grow text-sm text-gray-600 flex flex-col gap-y-4 justify-start'>
        <poster-row
          v-for='poster in filteredPosters'
          :key='`poster-${poster.id}`'
          :poster='poster'
          class='cursor-pointer opacity-100 lg:opacity-75 hover:opacity-100 hover:shadow-md lg:rounded-lg'
          @click.native='goToSinglePoster(poster.id)' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FileDownloader             from '@/components/FileDownloader.vue'
import PosterRow                  from '@/components/posters/PosterRow.vue'
import PosterControls             from '@/components/posters/PosterControls.vue'
import SponsorsCarouselFromConfig from '@/components/SponsorsCarouselFromConfig.vue'


export default {
  name: 'Posters',
  components: {
    FileDownloader,
    PosterRow,
    PosterControls,
    SponsorsCarouselFromConfig,
  },
  props: {
    shufflePosters: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('posters', [
      'filteredPosters',
      'hasPosters',
    ]),
    ...mapGetters('events', [
      'eventFileListOnPosters',
      'eventConfigPostersCarouselSponsors',
    ])
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('posters', [
      'getPosters',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
    ...mapMutations('posters', [
      'setShufflePostersRandomly',
    ]),
    goToSinglePoster (posterId) {
      this.$router.push({name: 'Poster', query: { poster_id: posterId }})
    }
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    if (!this.hasPosters) {
      this.setShufflePostersRandomly(this.shufflePosters)
      this.showFullpageLoadingIndicator()
      this.getPosters().then(() => {
        this.hideFullpageLoadingIndicator()
      })
    }
    this.getFilters()
  }
}
</script>
